

























import {Component, Prop, Vue} from 'vue-property-decorator';
import User from '@/models/User';
import ContactPerson from '@/models/ContactPerson';
import UserInitialsConfig from '@/misc/UserInitialsConfig';

@Component({})
export default class UserInitialsComponent extends Vue {

  /**
   * The user to be displayed
   */
  @Prop()
  public user!: User;

  /**
   * An optional config for the representation
   */
  @Prop({default: () => new UserInitialsConfig()})
  public config!: Partial<UserInitialsConfig>;


  /**
   *  Returns the user initials
   */
  public get initials(): string {
    if (this.user.firstName && this.user.lastName) {
      return `${this.user.firstName[0]}${this.user.lastName[0]}`.toUpperCase();
    }
    return '';
  }
}
